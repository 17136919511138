import React from 'react'
import BarChart from './charts/BarChart'
import TechChart from './charts/TechChart'
import { Route, Routes, Navigate } from 'react-router-dom'

import './App.css'

import Screen from './layouts/Screen'

//export const API_URL = "http://localhost:8080"
//export const API_URL = 'http://mmmmmm.io:8080'
export const API_URL = "https://mmmmmm.io:8446"

export const TheContext = React.createContext()
const initState = {
  entity0: { symbol: '', address: '' },
  entity1: { symbol: '', address: '' },
  userId: 'aaa',
  isBookAuthor: true,
  data: [],
  dataChanged: 0,
  params: {},
  cataIIOpen: true,
}

/**
 * One dispatch can call a reducer twice under certain conditions (for example: <React.StrictMode>)
 * but it would call it with the old state as input. So if it's pure, it's unobservable. A reducer
 * function must be pure.
 */
const reducer = (state, message) => {
  const newState = message.dataChanged
    ? { ...state, ...message, dataChanged: !state.dataChanged }
    : { ...state, ...message }

  return newState
}

function App() {
  const [state, dispatch] = React.useReducer(reducer, initState)

  return (
    <TheContext.Provider value={{ state, dispatch }}>
      <div className='App'>
        <Routes>
          <Route
            exact
            path='/'
            element={<Navigate to='/block/main/' />}
            // element={<Navigate to='/token/price/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48' />}
          />

          <Route path='*' element={<Screen />} />
        </Routes>
      </div>
    </TheContext.Provider>
  )
}

export default App
