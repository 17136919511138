import React from 'react'
import { Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { styled, ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'

import './layouts.css'
import { TheContext } from '../App'

import Navigator from './Navigator'
import Header from './Header'
import Footer from './Footer'

const merge = require('lodash.merge')

const AddressMain = React.lazy(() => import('./AddressMain'))
const TokenMain = React.lazy(() => import('./TokenMain'))
const BlockMain = React.lazy(() => import('./BlockMain'))
const TxMain = React.lazy(() => import('./TxMain'))
const SearchMain = React.lazy(() => import('./SearchMain'))

//const KhipuService = require('../services/KhipuService')
//const normAddress = KhipuService.normAddress

const baseTheme = createTheme()

const land = {
  name: 'land',
  palette: {
    background: {
      //default: 'white',
    },
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    info: {
      main: '#f9ebb0',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  logo: { background: 'url(/images/infini-matrix-land.svg)' },
  appbar: {
    color: '#202020',
    background: '#afd275',
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#6c6c6c',
        },
      },
    },
  },
}

const mocha = {
  name: 'mocha',
  palette: {
    background: {
      //default: 'white',
      //default: '#303030',
    },
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    info: {
      main: '#afd275',
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  logo: { background: 'url(/images/infini-matrix-mocha.svg)' },
  appbar: {
    color: '#202020',
    background: '#afd275',
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#6c6c6c',
        },
      },
    },
  },
}

const sea = {
  name: 'sea',
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  logo: { background: 'url(/images/infini-matrix-sea_preview.svg)' },
  appbar: {
    color: '#ffffff',
    background: '#0075be', // '#009be5', //'#0072b8',
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: baseTheme.palette.common.white,
        },
      },
    },
  },
}

const loadTheme = () => {
  const themeName = localStorage.getItem('theme')
  switch (themeName) {
    case 'land':
      return merge(commonTheme, land)
    case 'mocha':
      return merge(commonTheme, mocha)
    default:
      return merge(commonTheme, sea)
  }
}

const commonTheme = {
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          //backgroundColor: '#18202c',
        },
        paperAnchorDockedLeft: {
          borderRight: 'none', // leave for custum
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: baseTheme.spacing(0),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [baseTheme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: baseTheme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          //backgroundColor: '#404854',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: baseTheme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
}

const drawerWidth = 200
const rightPaneWidth = 0 // in theme.spaceing

const classes = {
  drawerDesk: {
    display: { xs: 'none', sm: 'block' },
    height: '100%',
  },
  drawerMobile: {
    display: { xs: 'block', sm: 'none' },
  },
  drawer: theme => ({
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    '& .MuiDrawer-paper': {
      borderRight: '1px solid #fafafa', // color of empty background, 'invisible' when there's gap between drawer and main pande
      //borderRight: '1px solid #c9c3bd', // color of chrome's toolbar bottom border
      //borderRight: '1px solid #f5f5f5', // color of the background color of header toolbar
      width: drawerWidth,
    },
  }),
  drawerAndMain: theme => ({
    minWidth:
      'calc(var(--vw, 1vw) * 100 - ' + drawerWidth + 'px - ' + theme.spacing(rightPaneWidth) + ')', // extends width to space left
  }),
  mainContainer: theme => ({
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
  }),
  main: theme => ({
    background: theme.palette.background.default,
    minHeight: 'calc(var(--vh, 1vh) * 100 - ' + 2 * 48 + 'px)', // get main streching enough to keep footer at bottom. header 48px, footer 48x
  }),
  rightPane: theme => ({
    minWidth: theme.spacing(rightPaneWidth),
  }),
}

function Screen() {
  const { dispatch } = React.useContext(TheContext)
  const { what, id } = useParams()

  const [theme, setTheme] = React.useState(createTheme(loadTheme()))

  const [inited, setInited] = React.useState(true)

  const [deskOpen, setDeskOpen] = React.useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const toggleTheme = () => {
    switch (theme.name) {
      case 'land':
        setTheme(commonTheme(createTheme(mocha)))
        localStorage.setItem('theme', 'mocha')
        break
      case 'mocha':
      default:
        setTheme(commonTheme(createTheme(land)))
        localStorage.setItem('theme', 'land')
        break
    }
  }

  /**
   * According to the W3C specification (17 March 2016):
   *   The innerWidth attribute must return the viewport width including the size of a
   *   rendered scroll bar (if any), or zero if there is no viewport.
   *
   *   ...
   *
   *   The clientWidth attribute must run these steps:
   *   1. If the element has no associated CSS layout box or if the CSS layout box is inline,
   *      return zero.
   *   2. If the element is the root element and the element's document is not in quirks mode, or
   *      if the element is the HTML body element and the element's document is in quirks mode,
   *      return the viewport width excluding the size of a rendered scroll bar (if any).
   *   3. Return the width of the padding edge excluding the width of any rendered scrollbar
   *      between the padding edge and the border edge, ignoring any transforms that apply to
   *      the element and its ancestors.
   *
   * So, scrollbarHeight = window.innerHeight - document.documentElement.clientHeight
   *
   * we'll use document.documentElement.clientHeight as viewportHeight to count in
   * scrollbar's height in 'main_footer' component. One solution is listening to window's
   * 'resize' event, and reset a --vh for calculating the actual viewport height:
   *
   *   const calcViewportHeight = () => {
   *     document.documentElement.style.setProperty(
   *       '--vh',
   *       `${document.documentElement.clientHeight * 0.01}px`
   *     )
   *   }
   *
   *   React.useLayoutEffect(() => {
   *     window.addEventListener('resize', calcViewportHeight)
   *     window.addEventListener('orientationchange', calcViewportHeight)
   *
   *     return () => {
   *       window.removeEventListener('resize', calcViewportHeight)
   *       window.removeEventListener('orientationchange', calcViewportHeight)
   *     }
   *   })
   *
   * The above solution works in most cases, except when the dimension changed in subcomponents,
   * for example, overflow in x cause the x-scrollbar appearing and the viewport height changes,
   * in which case, we should calc --vh in this component's useLayoutEffect.
   *
   * Thus we will listen window's 'resize' event here -- actually we have to listen this event
   * in Firefox even we -- and calculating '--vh' in sub-components' useLayoutEffect.
   *
   * NOTE There is still strange behavior in Android 9 of some mobile phones, which, when chrome
   * try to hide the address bar, it seems will set a non-reasonable window.innerHeight which is
   * almost double the size of document.documentElement.clientHeight, and cause stange viewport
   * behavior if we calc --vh accordingly.
   */
  React.useLayoutEffect(() => {
    function updateViewportDimension() {
      const w = document.documentElement.clientWidth
      const h = document.documentElement.clientHeight
      document.documentElement.style.setProperty('--vh', `${h * 0.01}px`)
      document.documentElement.style.setProperty('--vw', `${w * 0.01}px`)
    }

    window.addEventListener('resize', updateViewportDimension)
    window.addEventListener('orientationchange', updateViewportDimension)

    return () => {
      window.removeEventListener('resize', updateViewportDimension)
      window.removeEventListener('orientationchange', updateViewportDimension)
    }
  }, [])

  React.useEffect(() => {
    //fetchData()
  }, [])

  const toggleDeskDrawer = () => {
    setDeskOpen(!deskOpen)
  }

  const toggleMobileDrawer = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOpen = () => {
    setDeskOpen(true)
  }

  const handleDrawerClose = () => {
    setDeskOpen(false)
  }

  return inited ? (
    <ThemeProvider theme={theme}>
      <Box
        id='screen_root'
        display='flex'
        flexDirection='column'
        //margin='auto' // make content flex to center
        //sx={{ background: '#f5f5f5' }}
      >
        <CssBaseline />
        <Box>
          <Header
            toggleTheme={toggleTheme}
            toggleDeskDrawer={toggleDeskDrawer}
            toggleMobileDrawer={toggleMobileDrawer}
            deskOpen={deskOpen}
            mobileOpen={mobileOpen}
          />
        </Box>

        <Box
          id='drawer_and_main'
          // flexGrow={1}
          display='flex'
          flexDirection='row'
          sx={classes.drawerAndMain}
        >
          {deskOpen && (
            <Box id='drawer_container' sx={classes.drawerDesk}>
              <Drawer
                //anchor='left'
                // PaperProps={{ style: { position: 'absolute' } }}
                // BackdropProps={{ style: { position: 'absolute' } }}
                // ModalProps={{
                //   container: document.getElementById('drawer_container'),
                //   style: { position: 'absolute' },
                // }}
                elevation={0}
                sx={classes.drawer}
                variant='persistent'
                open={deskOpen}
              >
                <Routes>
                  <Route
                    exact
                    path='/:what/:pane/:address0/:address1'
                    element={
                      <Navigator
                        closeMobileDrawer={() => setMobileOpen(false)}
                        closeDeskDrawer={() => setDeskOpen(false)}
                      />
                    }
                  />
                  <Route
                    exact
                    path='/:what/:pane/:address0'
                    element={
                      <Navigator
                        closeMobileDrawer={() => setMobileOpen(false)}
                        closeDeskDrawer={() => setDeskOpen(false)}
                      />
                    }
                  />
                  <Route
                    exact
                    path='/:what/:pane'
                    element={
                      <Navigator
                        closeMobileDrawer={() => setMobileOpen(false)}
                        closeDeskDrawer={() => setDeskOpen(false)}
                      />
                    }
                  />
                </Routes>
              </Drawer>
            </Box>
          )}

          {mobileOpen && (
            <Box sx={classes.drawerMobile}>
              <Drawer
                sx={classes.drawer}
                variant='temporary'
                open={mobileOpen}
                onClose={() => setMobileOpen(false)}
                //onClick={() => setMobileOpen(false)}
              >
                <Routes>
                  <Route
                    exact
                    path='/:what/:pane/:address0/:address1'
                    element={
                      <Navigator
                        closeMobileDrawer={() => setMobileOpen(false)}
                        closeDeskDrawer={() => setDeskOpen(false)}
                      />
                    }
                  />
                  <Route
                    exact
                    path='/:what/:pane/:address0'
                    element={
                      <Navigator
                        closeMobileDrawer={() => setMobileOpen(false)}
                        closeDeskDrawer={() => setDeskOpen(false)}
                      />
                    }
                  />
                  <Route
                    exact
                    path='/:what/:pane'
                    element={
                      <Navigator
                        closeMobileDrawer={() => setMobileOpen(false)}
                        closeDeskDrawer={() => setDeskOpen(false)}
                      />
                    }
                  />
                </Routes>
              </Drawer>
            </Box>
          )}

          <Box id='main_container' flexDirection='row' flexGlow={1} width='100%'>
            <Box id='main' sx={classes.main}>
              <Suspense fallback={<div></div>}>
                <Routes>
                  <Route exact path='/token/:pane/:address0/:address1' element={<TokenMain />} />
                  <Route exact path='/token/:pane/:address0' element={<TokenMain />} />
                  <Route
                    exact
                    path='/address/:pane/:address0/:address1'
                    element={<AddressMain />}
                  />
                  <Route exact path='/address/:pane/:address0' element={<AddressMain />} />
                  <Route exact path='/block/:pane/' element={<BlockMain />} />
                  <Route exact path='/block/:pane/:address0' element={<BlockMain />} />
                  <Route exact path='/tx/:pane/:address0' element={<TxMain />} />
                  <Route exact path='/search/:searchText' element={<SearchMain />} />

                  {/*
                  <Route path='/:pane(recent|most|help|about)/:page?'>
                    <HomeMain />
                  </Route>
                  <Route path='/:what(book)/:id(0x)/:pane(add)'>
                    <UserMain />
                  </Route>
                  <Route path='/:what(book)/:id(0x)/:pane(search)/:text/:by/:page?'>
                    <SearchMain />
                  </Route>
                  <Route path='/:what(book|chapter)/:id/:pane(add|edit)'>
                    <EditMain />
                  </Route>
                  <Route path='/:what(book|chapter)/:id/:pane(preview)/:action'>
                    <EditMain />
                  </Route>
                  <Route path='/:what(book)/:id/:pane(readers)/:page?'>
                    <BookMain />
                  </Route>
                  <Route path='/:what(book)/:id/:pane(author)/:page?'>
                    <BookMain />
                  </Route>
                  <Route path='/:what(book|chapter)/:id/:pane?'>
                    <BookMain />
                  </Route>
                  <Route path='/:what(user)/:id/:pane(inbox|outbox)/:page?'>
                    <MessageMain />
                  </Route>
                  <Route path='/:what(user)/:id/:pane(readings|writings)/:page?'>
                    <UserMain />
                  </Route>
                  <Route path='/:what(user)/:id/:pane(list)/:page?'>
                    <UserMain />
                  </Route>
                  <Route path='/:what(user)/:id/:pane(txs)/:page/:subPage?'>
                    <UserMain />
                  </Route>
                  <Route path='/:what(user)/:id/:pane?'>
                    <UserMain />
                  </Route>
        */}
                </Routes>
              </Suspense>
            </Box>
            <Footer />
          </Box>
        </Box>
        {rightPaneWidth > 0 && (
          <Box id='screen_right_pane' flexGrow={1} sx={classes.rightPane}></Box>
        )}
      </Box>
    </ThemeProvider>
  ) : (
    <Box className='root' />
  )
}

export default Screen
